<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('PACKAGES') }}</h1>
        </div>
      </div>
    </section>
    <div class="container products">
      <div class="row">
        <div v-for="item of list" :key="item.id" class="col-md-6">
          <div class="home-top-cour row">
            <!--POPULAR COURSES IMAGE-->
            <div class="col-md-3">
              <router-link :to="$getLink() + 'packages/' + item.slug">
                <img :src="$baseUploadURL + item.image" alt=""
              /></router-link>
            </div>
            <!--POPULAR COURSES: CONTENT-->
            <div class="col-md-9 home-top-cour-desc">
              <router-link :to="$getLink() + 'packages/' + item.slug">
                <h3>{{ $getByLang(item.name) }}</h3>
              </router-link>
              <p v-html="$textSlice($getByLang(item.body), 300)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import products from './home/products';

export default {
  components: {
    products,
  },
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.$http.get('packages').then(
      async (res) => {
        this.list = await res.data;
        return await true;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>
